import styled from 'styled-components';
import { localize } from '../_localization';
import ConfigModal from '../ModalBasic';
import { useContext } from 'react';
import { GlobalStateContext } from '../../App';

export const InstagramNoteModal = ({ showInstagramNote, setShowInstagramNote }) => {
  const { globalState } = useContext(GlobalStateContext);

  const WelcomeNoteText = styled.div`
    text-align: center;
  `;
  return (
    <ConfigModal isOpen={showInstagramNote} onClick={() => setShowInstagramNote(false)}>
      <WelcomeNoteText>
        <p>
          {localize(
            'We notice you are using the Instagram browser.|Nous remarquons que vous utilisez le navigateur Instagram.|Wir stellen fest, dass Sie den Instagram-Browser verwenden.',
            globalState.language
          )}
        </p>
        <p>
          {localize(
            "Stratum Designer works best in landscape orientation, which Instagram does not allow.|Stratum Designer fonctionne mieux en orientation paysage, ce qu'Instagram ne permet pas.|Stratum Designer funktioniert am besten im Querformat, was Instagram nicht zulässt.",
            globalState.language
          )}
        </p>
        <p>
          {localize(
            'We suggest you click the 3 dots in the upper right corner,|Nous vous suggérons de cliquer sur les 3 points dans le coin supérieur droit,|Wir empfehlen Ihnen, auf die 3 Punkte in der oberen rechten Ecke zu klicken,',
            globalState.language
          )}
        </p>
        <p>
          {localize(
            'then choose "Open in Chrome/Safari/Edge"|puis choisissez "Ouvrir dans Chrome/Safari/Edge"|Wählen Sie dann „In Chrome/Safari/Edge öffnen“',
            globalState.language
          )}
        </p>
      </WelcomeNoteText>
    </ConfigModal>
  );
};

export default InstagramNoteModal;
