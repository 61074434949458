import { GlobalStateContext, ParamContext } from '../App'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BsArrowDownUp } from 'react-icons/bs'
import { isDT } from './Global'
import { ArMobileLinkModal } from './_commonComponents'
import ErrorBoundary from './ErrorBoundary'
import BannerTop from './BlockBanner'
import { arUrl } from './_makeUrls'
import ArButton from './ArButton'
import ClickableIcons from './ClickableIcons'

export default function BlockModelPresentation({ children, omitBanner, omitAR, omitUpDown, dimensionsClicked }) {
	const { globalState } = useContext(GlobalStateContext)
	const { currentParams } = useContext(ParamContext)
	const [qrModalIsOpen, setQrModalIsOpen] = useState(false)
	const [qrUrl, setQrUrl] = useState(false)

	useEffect(() => {
		setQrUrl(arUrl(globalState.productId, currentParams))
	}, [globalState, currentParams])

	function UpDownDragIcon() {
		return (
			<UpDownDragIconDiv>
				<UpDownDragIconText>
					<BsArrowDownUp size='30px' />
				</UpDownDragIconText>
			</UpDownDragIconDiv>
		)
	}

	return (
		<ErrorBoundary>
			<FullScreenContainer>
				<MainPageContainerDiv mobile={!isDT}>
					{!omitBanner && <BannerTop alwaysFullWidth={true} />}
					<ContentDiv>
						<ClickableIcons dimensionsClicked={dimensionsClicked} />
						{!omitAR && <ArButton setQrModalIsOpen={setQrModalIsOpen} />}
						{!omitUpDown && !isDT && <UpDownDragIcon />}
						{children}</ContentDiv>

				</MainPageContainerDiv>
				<ArMobileLinkModal
					isOpen={qrModalIsOpen}
					setIsOpen={setQrModalIsOpen}
					qrUrl={qrUrl}
					language={globalState.language}
				/>
			</FullScreenContainer>
		</ErrorBoundary>
	)
}

const FullScreenContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`

const MainPageContainerDiv = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`

const ContentDiv = styled.div`
	position: relative;
	height: 100%;
`

const UpDownDragIconDiv = styled.div`
	position: absolute;
	top: 40px;
	left: 5px;
`

const UpDownDragIconText = styled.p``

