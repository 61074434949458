// import CopyIcon from '../Components/images/copy.svg';
import DimensionsSvg from '../Components/images/dimensions.svg';
import React from 'react';
import { Link } from 'react-router-dom';

export const ClickableIcons = ({ dimensionsClicked }) => {
  return (
    <div className="clickable-icons">
      <Link onClick={dimensionsClicked} className="dimensions-icon">
        <img src={DimensionsSvg} alt="dimensions" />
      </Link>
      {/* <Link onClick={saveClicked} className="copy-icon">
        <img src={CopyIcon} alt="dimensions" />
      </Link> */}
    </div>
  );
};

export default ClickableIcons;
