import { GlobalStateContext, ParamContext } from '../App'
import BlockModelPresentation from './BlockModelPresentation'

import { useEffect, useContext } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import BlockConfigurator from './BlockConfigurator'
import { unabbreviateParams } from './_makeUrls'

export default function Viewer({ omitBanner, omitAR, omitUpDown }) {
	const { setGlobalStateItem } = useContext(GlobalStateContext)
	const { setCurrentParams } = useContext(ParamContext)

	const { productId } = useParams()
	const [searchParams] = useSearchParams()
	const incomingParamsEncoded = Object.fromEntries(new URLSearchParams(searchParams)) // convert to an object (flat list of key:value pairs)
	// console.log('View..Block', productId, incomingParamsEncoded)

	useEffect(() => {
		// set global state for other components, e.g., ARButton in MainPageContainer
		setGlobalStateItem('productId', productId)
		setCurrentParams(unabbreviateParams(productId, incomingParamsEncoded))
		// eslint-disable-next-line
	}, [])

	return (
		<BlockModelPresentation omitBanner={omitBanner} omitAR={omitAR} omitUpDown={omitUpDown}>
			{<BlockConfigurator productId={productId} />}
		</BlockModelPresentation>
	)
}
