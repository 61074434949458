import preval from 'babel-plugin-preval/macro'

function getUiSpecTree(productId) {
	const modelCode = productId.split('.')[0]
	const productCode = productId.split('.')[2]

	// sample lines from models.txt:
	// modelCode: name:    groups                                         :placement :camHtPct :camAzDeg :  priceBase :   priceArea :  priceMatPrem
	// AW:        Wave:    size, shape, thickness, material               :floor     :150      :60       :  550       :   1000      :  11=1.3, 21=1.15, 22=1.15
	// AC:        Cascade: size, thickness, legangle, random, material    :floor     :110      :-30      :  1500      :   750       :  11=1.3, 21=1.15, 22=1.15
	// AS:        Stream:  size, fan, material                            :wall      :200      :-30      :  200       :   1500      :  11=1.3, 21=1.15, 22=1.15

	// Notes for models.txt
	// The ids in the 'groups' correspond to the groupCode in groups.txt
	// The 'placement' is used in VR and in shadow creation
	// The 'cam...' fields specify initial camera position
	// The 'price...' fields are used in the 'manufacture' page

	const model = uiSpecModels.find((model) => model.modelCode === modelCode)
	console.assert(model !== undefined, 'cannot find the requested model in uiSpecModels:', modelCode, uiSpecModels)
	// console.log('ui spec', structuredClone(model))

	// this is just to show the friendly preset name; no related logic
	const product = uiSpecProducts.find(
		(product) => product.modelCode === modelCode && product.productCode === productCode
	)
	console.assert(
		product !== undefined,
		'cannot find the requested product in uiSpecProducts. modelCode:',
		modelCode,
		', productCode:',
		productCode,
		uiSpecProducts
	)
	model['productName'] = product.name

	// sample lines from groups.txt:
	// modelCode:      groupCode  :  name                       :  params:
	// AW:             size       :  Size|Taille|Größe          :  WidthMax, Height, SurfaceLength
	// AW:             thickness  :  Thickness|Épaisseur|Dicke  :  EndThickness, TransitionThicknessChg

	// Notes for groups.txt
	// The ids in the 'params' correspond to the rhinoName in params.txt
	// The 'name' is a multi-language string that is localized with the 'localize' function in _localization.js

	const groupCodes = model.groups.split(',').map((code) => code.trim())

	const groups = groupCodes.map((groupCode) =>
		uiSpecGroups.find(
			(group) => group.groupCode === groupCode && (group.modelCode === modelCode || group.modelCode === '*')
		)
	)

	console.assert(!groups.includes(undefined), 'cannot find a group', groupCodes, groups)
	// model.groups = groups // replace the group id's with group objects

	// sample lines from params.txt:
	// modelCode: rhinoName               : abbrev : video                                : name                  : unit                           :   min   : max :rev: step :  keyvaluepairs
	// AW:        WidthMax                : w      :                                      : Width|Largeur|Breite  : cm                             :   18.01 : 100 :   : 1.8  :
	// AW:        TransitionThicknessChg  : tt     : AW_param_TransitionThicknessChg.mp4  : Top|Dessus|Oberseite  : Thin/Thick|Fin/Épais|Dünn/Dick :   -2    : 2   :   : 0.1  :
	//  *:        MaterialThicknessMM     : m      :                                      : Layer Thickness       :                                :   -     : -   :   : -    :  24mm=24, 27mm=27

	// Notes for params.txt
	// An asterisk for modelCode is a parameter that can be used for all models
	// The notatation 'Width|Largeur|Breite' is a multi-language string that is localized with the 'localize' function in _localization.js
	// 'Thin/Thick' represents two labels for units; the first on the left (or low) side of the slider and the second is on the right (or high)
	// An asterisk in 'rev' column indicates that the slider should go from high-to-low values rather than the normal low-to-high
	// 'keyvaluepairs' indicate that selection buttons are used instead of sliders
	// the 'abbrev's must be unique within a model

	let uiGroups = [];
	groups.forEach((group) => {
		const paramCodes = group.params.split(',').map((code) => code.trim())
		const params = paramCodes.map((paramCode) =>
			uiSpecParams.find(
				(param) => param.rhinoName === paramCode && (param.modelCode === modelCode || param.modelCode === '*')
			)
		)
		console.assert(!params.includes(undefined), 'cannot find a param in the following group:', paramCodes, 'found only these params:', params)
		group.params = params // replace the param id's with param objects

		if (group.groupCode === "basic") {
			uiGroups.push(group);
		} else {
			if (!uiGroups.find(({ modelCode, groupCode }) => groupCode === "other")) {
				uiGroups.push({
					modelCode,
					groupCode: "other",
					name: "Other",
					params: []
				})
			}

			uiGroups.find(({ groupCode }) => groupCode === "other").params.push(...params)
		}
		// console.log('params ', params)
	})
	// console.log('ui tree', model);
	model.groups = uiGroups;
	return model
}

//========================================================
// The following is done at compile time so we can read
// data from the server that isn't available in the browser.
//========================================================

// read ui spec data from the server at compile time. TODO make this a dev dependency
const uiRawSpecModels = preval`
const fs = require('fs')
module.exports = fs.readFileSync('./src/data/models.txt', 'utf8')
`
const uiRawSpecGroups = preval`
const fs = require('fs')
module.exports = fs.readFileSync('./src/data/groups.txt', 'utf8')
`
const uiRawSpecParams = preval`
const fs = require('fs')
module.exports = fs.readFileSync('./src/data/params.txt', 'utf8')
`
const uiRawSpecProducts = preval`
const fs = require('fs')
module.exports = fs.readFileSync('./src/data/products.txt', 'utf8')
`
// console.log('=======================starting processing of compile-time data')
// convert to json format
const uiSpecModels = createUiSpec(uiRawSpecModels)
const uiSpecGroups = createUiSpec(uiRawSpecGroups)
const uiSpecParams = createUiSpec(uiRawSpecParams)
const uiSpecProducts = createUiSpec(uiRawSpecProducts)
// console.log('=======================finished processing of compile-time data')

function createUiSpec(uiRawSpec) {
	const allRawLines = uiRawSpec.split('\n').filter((line) => line.trim() !== '')
	// console.log(allRawLines)
	// the column header names are in the first line
	let colNames = splitLine(allRawLines[0])
	// the remaining lines are the specs
	let specLinesCells = allRawLines.slice(1).map((line) => splitLine(line))
	// remove the empty lines at the end if there any
	specLinesCells = specLinesCells.filter((element) => {
		return Object.keys(element).length !== 0
	})
	// convert to json
	return specLinesCells.map((cells) => jsonify(colNames, cells))
}

// convert to json format by adding the column headers to each value
function jsonify(names, values) {
	var items = {}
	names.forEach((name, index) => (items[name] = index < values.length ? values[index] : '-')) // add empties if the values row has empty cells at the end
	// console.log(names, values, items)
	return items
}

// split the raw lines into individual elements (i.e., the cells)
function splitLine(uiRawSpecLine) {
	const elements = uiRawSpecLine.split(':')
	const result = []
	// trim out the formatting-only tabs
	elements.forEach((element) => {
		const str = element.trim()
		result.push(str)
	})
	if (result[result.length - 1] === '') {
		result.pop()
	} // if present, remove the empty cell at the end
	return result
}

function getRawSpecsModel() {
	return uiRawSpecModels
}

function getRawSpecsProduct() {
	return uiRawSpecProducts
}

function getRawSpecsParams() {
	return uiRawSpecParams
}

// model specs in object form; for all models (productId undefined), or a particular productId
function modelSpecs(productId) {
	const allModels = createUiSpec(getRawSpecsModel())

	if (productId === undefined) return allModels // EARLY RETURN

	const modelCode = productId.split('.')[0] // AW, AS, etc.
	return allModels.filter((m) => m.modelCode === modelCode)[0] // return the single spec object for productId
}

function modelPlacement(productId) {
	return modelSpecs(productId).placement
}

function isModelPlacementWall(productId) {
	return modelPlacement(productId) === 'wall'
}

function cameraProps(productId) {
	const mSpec = modelSpecs(productId)
	const camHtPct = mSpec.camHtPct
	const camAzDeg = mSpec.camAzDeg
	return { camHtPct, camAzDeg }
}

function price(productId, area, materialIdExternal) {
	const mSpec = modelSpecs(productId)
	const priceBase = +mSpec.priceBase
	const priceArea = +mSpec.priceArea
	let materialPremium = +1
	// material premiums are of the form: 11=1.3, 21=1.15, 22=1.15
	if (mSpec.priceMatPrem.includes(materialIdExternal + '=')) {
		// if we're here we know the specs include the desired material id
		const matPremsKVs = mSpec.priceMatPrem.split(',')
		const matPremKV = matPremsKVs.filter((pairStr) => pairStr.split('=')[0] === materialIdExternal)[0]
		const matPrem = matPremKV.split('=')[1]
		materialPremium = +matPrem
	}
	const _price = (priceBase + priceArea * area) * materialPremium
	// console.log(_price, area, priceBase, priceArea, materialPremium)
	return _price
}

// return the specs for all parameters, or for a particular productId
function paramSpecs(productId) {
	const allParams = createUiSpec(getRawSpecsParams())

	if (productId === undefined) return allParams // EARLY RETURN

	const modelCode = productId.split('.')[0] // AW, AS, etc.
	return allParams.filter((m) => (m.modelCode === modelCode) | (m.modelCode === '*')) // return the single spec object for productId
}

// Normalize all text to a particular case format.
// This works well for 'ALL UPPER', or 'all lower'. 'Capitalized' works well for English
// but not a well for other languages. So, if capitalized is desired, that must be done
// in the original source, such as params.txt.
function toProperCase(str) {
	// console.log(str)
	return str
	// return str.indexOf(' ') === -1
	// 	? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
	// 	: str
	// 			.split(' ')
	// 			.map((word) => {
	// 				return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
	// 			})
	// 			.join(' ')
}

function truncateString(string, limit) {
	if (string.length > limit) {
		return string.substring(0, limit) + '..'
	} else {
		return string
	}
}

export {
	getUiSpecTree,
	getRawSpecsProduct,
	modelPlacement,
	isModelPlacementWall,
	cameraProps,
	paramSpecs,
	toProperCase,
	truncateString,
	price,
}
