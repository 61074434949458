import { useContext } from "react"
import { GlobalStateContext, ParamContext } from "../App"
import { localize } from "./_localization"
import { ArMobileLinkButton, CommonAButton } from "./_commonComponents"
import { arUrl } from "./_makeUrls"
import { logAction } from "./_logging"
import { isDT, isArPossible } from './Global'

export default function ArButton({ setQrModalIsOpen }) {
  const { globalState } = useContext(GlobalStateContext);
  const { currentParams } = useContext(ParamContext)

  function QRClicked() {
    setQrModalIsOpen(true)
    logAction(globalState.productId, 'ArMobileLink')
  }

  const linkStyle = isDT
    ? {}
    : {
      backgroundColor: 'transparent',
      display: 'inline',
      color: 'black',
      textAlign: "center"
    }

  const ViewInYourSpaceText = () => (<>{isDT
    ? localize('View In AR|Voir dans Votre Espace|Ansicht in Ihrem Raum', globalState.language)
    : localize('AR', globalState.language)}</>);

  const buttonStyle = isDT ? { bottom: '30px', left: "30px" } : { width: '50px', top: "10px", right: "10px" }

  return isArPossible ? (
    <CommonAButton
      text={
        <>
          {/* <img src={"/images/ar.jpg"} style={viewInYourImgStyle} alt='AR' /> */}
          <ViewInYourSpaceText />
        </>
      }
      href={arUrl(globalState.productId, currentParams)}
      target='_blank'
      buttonStyleClass="arButton"
      buttonStyleOverrides={buttonStyle}
      linkStyleOverrides={linkStyle}
      dataTip={localize(
        'Use Augmented Reality on your mobile device to place your furniture in your space|Utilisez la réalité augmentée sur votre appareil mobile pour placer vos meubles dans votre espace|Verwenden Sie Augmented Reality auf Ihrem Mobilgerät, um Ihre Möbel in Ihrem Raum zu platzieren',
        globalState.language
      )}
    />
  ) : (
    <ArMobileLinkButton
      text={
        <>
          {/* <img src={"/images/ar.jpg"} style={viewInYourImgStyle} alt='AR' /> */}
          <ViewInYourSpaceText />
        </>
      }
      buttonStyleClass="arButton"
      buttonStyleOverrides={buttonStyle}
      linkStyleOverrides={linkStyle}
      buttonClicked={QRClicked}
    />
  )
}