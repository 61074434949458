import { GlobalStateContext } from '../App'
import BlockModelPresentation from './BlockModelPresentation'
import { useState, useEffect, useContext, useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import ReactPlayer from 'react-player/file'
import { isInstagram, isDT } from './Global'
import MainConfiguratorBlock from './BlockConfigurator'
import PersonalizationBlock from './PersonalizationBlock'
import { getUiSpecTree } from './_uiHelpers'
import { DESIGNER_URL } from './__urlsSystem'
import { unabbreviateParams } from './_makeUrls'
import ConfigModal from './ModalBasic'
import { logSession } from './_logging'
import InstagramNoteModal from './Modals/InstagramNoteModal'

export default function Configurator({ omitBanner, omitAR, omitUpDown }) {
	const { globalState, setGlobalStateItem } = useContext(GlobalStateContext)

	const personalizationBlockDivRef = useRef()

	const { productId } = useParams()
	const [searchParams] = useSearchParams()
	const incomingParamsEncoded = Object.fromEntries(new URLSearchParams(searchParams)) // convert to an object (flat list of key:value pairs)
	// console.log('Config..Block', productId, incomingParamsEncoded)

	const [uiTree, setUiTree] = useState()
	const [personalizationBlock, setPersonalizationBlock] = useState()
	const [saveModalIsOpen, setSaveModalIsOpen] = useState(false)

	// intro notes and warnings
	const [showInstagramNote, setShowInstagramNote] = useState(false)
	const [pauseLandscapeCheck, setPauseLandscapeCheck] = useState(true) // don't check for landscape until intro notes are started
	const [hasSeenLandscapeRec, setHasSeenLandscapeRec] = useState(false) // show LandscapeRec only once during a session

	useEffect(() => {
		setGlobalStateItem('showDimensions', false)
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		setGlobalStateItem('productId', productId)
		setUiTree(getUiSpecTree(productId)) // get the spec for the parameter adjusters for this productId (and get the friendly names)
		logSession(productId)
		// eslint-disable-next-line
	}, [productId])

	// set the major blocks in useEffect's so they don't get re-rendered on other global state changes
	useEffect(() => {
		if (uiTree === undefined) return
		setPersonalizationBlock(
			<PersonalizationBlock
				className="personalizationBlock"
				uiTree={uiTree}
				productId={productId}
				incomingParams={unabbreviateParams(productId, incomingParamsEncoded)}
				personalizationBlockDivRef={personalizationBlockDivRef}
				dimensionsClicked={dimensionsClicked}
				saveModalIsOpen={saveModalIsOpen}
				setSaveModalIsOpen={setSaveModalIsOpen}
			/>
		)
		// eslint-disable-next-line
	}, [uiTree, productId])

	// show the welcome popup upon initial load after a short delay
	useEffect(() => {
		const timer = setTimeout(() => {
			isInstagram && setShowInstagramNote(true)
			setPauseLandscapeCheck(false) // don't check for landscape until these notes get started
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		if (globalState?.windowProps === undefined || pauseLandscapeCheck || isInstagram) return
		if (hasSeenLandscapeRec) return // don't show it again during this session
		setHasSeenLandscapeRec(true)
	}, [globalState, pauseLandscapeCheck, hasSeenLandscapeRec])

	const dimensionsClicked = () => {
		setGlobalStateItem('showDimensions', !globalState.showDimensions)
	}

	const Video = () => {
		// console.log(DESIGNER_URL + '/videos/' + globalState.videoFilename)

		return (
			<>
				<VideoTitle>{globalState.videoTitle}</VideoTitle>
				<ReactPlayer
					url={DESIGNER_URL + '/videos/' + globalState.videoFilename}
					playing={true}
					controls={true}
					loop={true}
					muted={true}
					playsinline={true}
					onReady={() => true}
					width='100%'
					height='100%'
					config={{
						file: {
							attributes: {
								controlsList: 'nofullscreen',
							},
						},
					}}
				/>
			</>
		)
	}

	return (
		<BlockModelPresentation omitBanner={omitBanner} omitAR={omitAR} omitUpDown={omitUpDown} dimensionsClicked={dimensionsClicked}>
			<ModelDiv>
				<MainConfiguratorBlock productId={productId} personalizationBlockDivRef={personalizationBlockDivRef} mobile={!isDT} /></ModelDiv>
			<PersonalizationDiv mobile={!isDT}>{personalizationBlock}</PersonalizationDiv>
			<ConfigModal isOpen={globalState.showVideoModal} onClick={() => setGlobalStateItem('showVideoModal', false)}>
				<VideoDiv>
					<Video />
				</VideoDiv>
			</ConfigModal>

			{/* <ActionDiv mobile={!isDT}>
				<SaveModal saveModalIsOpen={saveModalIsOpen} setSaveModalIsOpen={setSaveModalIsOpen} />
			</ActionDiv> */}

			<InstagramNoteModal showInstagramNote={showInstagramNote} setShowInstagramNote={setShowInstagramNote} />
		</BlockModelPresentation>
	)
}

const ModelDiv = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`

const PersonalizationDiv = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	top: ${(props) => props.theme.PersonalizationDiv_top};
	pointer-events: none;
	${(props) => props.mobile && `
		width: 100%;
		top: auto;
		bottom: 0;	
	`}
`

const VideoDiv = styled.div`
	position: relative;
	width: ${(props) => props.theme.VideoDiv_width};
	height: ${(props) => props.theme.VideoDiv_height};
`

const VideoTitle = styled.p`
	position: absolute;
	top: ${(props) => props.theme.VideoTitle_top};
	left: 20px;
	right: 20px; // don't cover the close X
	text-align: center;
	// text-transform: capitalize;
`
