import { GlobalStateContext, ParamContext } from '../App'
import { useEffect, useContext, useState, useRef } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { H1, H4 } from './Global'
import BlockParams from './BlockParams'
import { CommonActionButton } from './_commonComponents'
import { saveQueryParams } from './_makeUrls'
import { isModelPlacementWall } from './_uiHelpers'
import {
	isDT,
	colorButton0,
	colorButton0Text,
	colorButton1,
	colorButton1Text,
	colorInfoContainer,
	colorProductInfoDiv,
	defaultBorderRadius,
} from './Global'
import { logAction } from './_logging'
import { localize } from './_localization'
import ClickableIcons from './ClickableIcons'
import '../styles.css'

export default function PersonalizationBlock({ uiTree, productId, incomingParams, personalizationBlockDivRef }) {
	const { globalState, setGlobalStateItem } = useContext(GlobalStateContext)
	const { currentParams } = useContext(ParamContext)
	const [paramsHeightChanged, setParamsHeightChanged] = useState(false) // the ParamsBlock sets this when it changes height
	const [, setShowDesktopSwitchText] = useState(false);
	const upperDivRef = useRef()

	useEffect(() => {
		setGlobalStateItem('showContextPerson', false)
		setGlobalStateItem('isModelPlacementWall', isModelPlacementWall(productId)) // determine placement, 'floor' or 'wall'
		if (!isDT) {
			setShowDesktopSwitchText(true);
			setTimeout(() => {
				setShowDesktopSwitchText(false);
			}, 10000)
		}
		// eslint-disable-next-line
	}, [])

	// react to a height change in the params block to see if action buttons fit below that block
	useEffect(() => {
		if (!paramsHeightChanged) return
		setParamsHeightChanged(false)
	}, [upperDivRef.current?.clientHeight, paramsHeightChanged])

	// empty useEffect. uncomment for debugging
	useEffect(() => {
	}, [currentParams])

	function enquireClicked() {
		// const emailTo = 'brian@stratumbespoke.com'
		// const emailSub = localize(
		// 	'I am enquiring about a custom design|Je me renseigne sur un design personnalisé|Ich erkundige mich nach einem kundenspezifischen Design',
		// 	globalState.language
		// )
		// const design = productId + '%0A' + saveUrl(productId, currentParams).search.substring(1).replace(/&/g, '%0A')
		// const emailBody =
		// 	localize(
		// 		'Hi Brian,%0A%0APlease tell me what you think about my design.%0A%0A<add any comments for Brian here>%0A%0AHere is my personalized Blueprint:%0A%0A|Bonjour Brian,%0A%0AVeuillez me dire ce que vous pensez de mon design.%0A%0A<ajoutez des commentaires pour Brian ici>%0A%0AVoici mon plan personnalisé :%0A%0A|Hallo Brian,%0A%0ABitte sag mir, was du von meinem Design hältst.%0A%0A<Füge hier Kommentare für Brian hinzu>%0A%0AHier ist mein personalisierter Bauplan:%0A%0A',
		// 		globalState.language
		// 	) + design
		// window.open('mailto:' + emailTo + '?subject=' + emailSub + '&body=' + emailBody, '_self')

		const encodedParams = btoa(saveQueryParams(productId, currentParams));
		window.parent.postMessage({ action: 'callEnquire', data: encodeURIComponent(encodedParams) }, '*');
		logAction(productId, 'Enquire');
	}

	const saveClicked = () => {
		// setSaveModalIsOpen(true)
		// const url = saveUrl(productId, currentParams).toString()
		// navigator.clipboard.writeText(url)
		const encodedParams = btoa(saveQueryParams(productId, currentParams));
		window.parent.postMessage({ action: 'callSave', data: encodeURIComponent(encodedParams) }, '*');
		logAction(productId, 'Save')
	}

	const dimensionsClicked = () => {
		setGlobalStateItem('showDimensions', !globalState.showDimensions)
	}

	const actionButton_large_style = {
		borderRadius: "8px"
	}

	const actionButton_small_style = {
		width: '100%',
		height: "50px",
		border: '1px solid black',
		borderRadius: '8px',
		marginBottom: '5px'
	}

	const actionButton_style = isDT ? actionButton_large_style : actionButton_small_style

	// ActionDiv
	const ActionDiv = styled.div`
		pointer-events: all;
		margin: 10px 10px 30px 10px;
		${(props) => props.mobile && `
			display: block;
			margin: auto;
			width: 95%;
		`}
	`

	// ViewOptionsDiv
	const ViewOptionsDiv = styled.div`
		display: flex;
		justify-content: space-evenly;
		place-content: center;
		margin: 0 5px 0 0;
		${(props) => props.mobile && `
			pointer-events: all;
			margin: 0 10px;
			display: flex;
			flex-direction: column;
			justify-content: end;
			text-align: center;
		`}
	`
	return (
		<div style={{ ...(isDT && { height: '100%' }), pointerEvents: 'none' }}>
			{/* create an overlay on the entire screen to block ui */}
			<div
				style={{
					display: globalState.isModelLoading ? 'block' : 'none',
					position: 'absolute',
					width: '100%',
					height: '100%',
					zIndex: 2,
					pointerEvents: 'all',
				}}
			/>
			{/* {!isDT && <ViewOptionsDiv mobile={!isDT} className={showDesktopSwitchText ? "animated" : "animated fadeOut"}>
				<SpeedOptionHeading mobile={!isDT}>{localize('For more advanced features, we recommend switching to desktop version', globalState.language)}</SpeedOptionHeading>
			</ViewOptionsDiv>
			} */}
			<PersonalizationBlockDiv mobile={!isDT} ref={personalizationBlockDivRef}>
				{isDT && <ReactTooltip delayShow={100} />}
				<UpperDiv ref={upperDivRef}>
					<ProductInfoDivWrapper className="personalize" mobile={!isDT}>
						<>
							{isDT && (
								<>
									<ClickableIcons dimensionsClicked={dimensionsClicked} saveClicked={saveClicked} />

									<ProductInfoDiv>
										<ModelText>
											{localize('Personalize Your Design', globalState.language)}
										</ModelText>
									</ProductInfoDiv>
								</>
							)}
							<BlockParams
								uiTree={uiTree}
								productId={productId}
								incomingParams={incomingParams}
								setParamsHeightChanged={setParamsHeightChanged}
							/>
						</>

					</ProductInfoDivWrapper>
					{isDT && <ViewOptionsDiv mobile={!isDT}>
						<SpeedOptionHeading mobile={!isDT}>
							{localize('Individually crafted in Geneva, Switzerland.', globalState.language)}
							<br />
							{localize('Current lead time approx. 6-8 weeks.', globalState.language)}
						</SpeedOptionHeading>
					</ViewOptionsDiv>}
				</UpperDiv>
				<ActionDiv mobile={!isDT}>
					<CommonActionButton
						onClick={saveClicked}
						text={
							<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "18px" }}>
								{localize('Save design|Sauver/Partager|Speichern/Teilen', globalState.language)}
							</span>
						}
						buttonStyleOverrides={{ backgroundColor: colorButton0, color: colorButton0Text, ...actionButton_style }}
						dataTip={localize(
							'Get a URL to save your custom design|Obtenez une URL pour enregistrer votre design personnalisé|Holen Sie sich eine URL, um Ihr benutzerdefiniertes Design zu speichern',
							globalState.language
						)}
					></CommonActionButton>

					<CommonActionButton
						onClick={enquireClicked}
						text={
							<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "18px" }}>
								{localize('Enquire|Renseigner|Anfragen', globalState.language)} &nbsp;{' '}
							</span>
						}
						buttonStyleOverrides={{ backgroundColor: colorButton1, color: colorButton1Text, ...actionButton_style, ...isDT && { marginLeft: '15px' } }}
						dataTip={localize(
							'Send an email to Stratum to evaluate your design|Envoyez un e-mail à Stratum pour évaluer votre conception|Senden Sie eine E-Mail an Stratum, um Ihr Design zu bewerten',
							globalState.language
						)}
					></CommonActionButton>
				</ActionDiv>
				{!isDT && <ViewOptionsDiv mobile={!isDT}>
					<SpeedOptionHeading mobile={!isDT}>
						{localize('Individually crafted in Geneva, Switzerland.', globalState.language)}
						<br />
						{localize('Current lead time approx. 6-8 weeks.', globalState.language)}
					</SpeedOptionHeading>
				</ViewOptionsDiv>}
			</PersonalizationBlockDiv>
		</div >
	)
}

const PersonalizationBlockDiv = styled.div`
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: ${(props) => props.theme.PersonalizationBlockDiv_width};
	user-select: none;
	margin: 0 30px 0 0;
	${(props) => props.mobile && `
		margin: 0;
		width: 100%;	
		background-color: white;
	`}
`

const UpperDiv = styled.div`
	// padding-top: ${defaultBorderRadius + 4}px;
	// padding-bottom: ${defaultBorderRadius + 3}px;  // the bottom section has an adjacent double-wide divider, which makes this looks larger
	// background-color: ${colorInfoContainer};
	border-radius: ${defaultBorderRadius}px;
	margin: ${(props) => props.theme.PersonalizationBlockDiv_margin};
	pointer-events: all;
`

const ProductInfoDivWrapper = styled.div`
	background-color: ${colorProductInfoDiv};
	${(props) => props.mobile && `
		margin: 0;
		padding: 25px 0;
	`}
`

const ProductInfoDiv = styled.div`
	height: ${(props) => props.theme.Banner_height};
	background-color: ${colorProductInfoDiv};
`

const ModelText = styled(H1)`
	margin: ${(props) => props.theme.ModelText_margin};
	color: ${(props) => props.theme._uiTextColorInverse};
	// text-transform: capitalize;
	text-align: center;
	padding-right: 24px;
`

const SpeedOptionHeading = styled(H4)`
	margin: 0 3px 0 0;
	margin-bottom: 15px;
	line-height: 15px;
	font-size: 12px;
	text-align: center;
	${(props) => props.mobile && `
		margin: 10px 0 10px 0;
	`}
`
