import { GlobalStateContext, ParamContext, SpeedParamContext } from '../App'
import styled from 'styled-components'
import { isDT, H1 } from './Global'
import { useEffect, useState, useContext, startTransition } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { calcModel, processModel, dimensionsDisplay, contextPerson } from './_gltfModel'
import { lighting } from './_scene'

// the main block, consisting of the three.js stuff: 1) static scene and 2) dynamic gltf model
export default function MainConfiguratorBlock({ mobile, productId, personalizationBlockDivRef }) {
	const { globalState, setGlobalStateItem } = useContext(GlobalStateContext)
	const { currentParams } = useContext(ParamContext)
	const { speedParams } = useContext(SpeedParamContext);
	const [height, setHeight] = useState('100%');
	const ref = personalizationBlockDivRef?.current;

	const [staticScene, setStaticScene] = useState()
	const [gltf, setGltf] = useState()
	const [dimensions, setDimensions] = useState()
	const [person, setPerson] = useState()
	const [showInitialLoading, setShowInitialLoading] = useState(true)

	const [cameraPosition, setCameraPosition] = useState()
	const [cameraTarget, setCameraTarget] = useState()

	useEffect(() => {
		setShowInitialLoading(true)
	}, [])

	// get the static scene only on first render of productId
	useEffect(() => {
		setStaticScene(lighting(productId, currentParams))
	}, [productId, currentParams])

	// calculate the gltf model
	useEffect(() => {
		if (Object.keys(currentParams).length === 0) return // no parameters loaded yet

		// console.log('ConfigBlk..2 calc start', productId, currentParams, speedParams)
		setGlobalStateItem('isModelLoading', true) // start the blur effect
		const params = JSON.parse(JSON.stringify(currentParams)) // deep copy from currentParams
		const allParams = { ...params, ...speedParams } // append the speedParams to currentParams to affect Rhino speed
		const props = { productId, currentParams: allParams } // prepare all arguments for calcModel
		calcModel(props).then((g) => setGltf(g)) ////////////////// THE MAIN EVENT IS HERE \\\\\\\\\\\\\\\\\\\\\\\
		// eslint-disable-next-line
	}, [currentParams, speedParams])

	useEffect(() => {
		if (gltf === undefined) return

		// console.log('ConfigBlk..3 calc done', gltf)
		if (gltf !== undefined) {
			setGlobalStateItem('isModelLoading', false)
			setShowInitialLoading(false)
		}
		const cameraProps = processModel(gltf, productId) // set shadow, etc.
		setCameraPosition(cameraProps?.cameraPosition?.toArray())
		setCameraTarget(cameraProps?.cameraTarget?.toArray())
		// eslint-disable-next-line
	}, [gltf])

	useEffect(() => {
		if (gltf === undefined) return

		// the following is needed because dimensionsDisplay is a 'synchronous' result being used in Suspense below; not fully understood
		startTransition(() => {
			setDimensions(dimensionsDisplay(gltf, productId, globalState.showDimensions))
		})
		startTransition(() => {
			setPerson(contextPerson(gltf, productId, globalState.showContextPerson))
		})
		// eslint-disable-next-line
	}, [gltf, globalState])

	useEffect(() => {
		const calculateHeight = () => {
			if (mobile) {
				const newHeight = `${window.innerHeight - ref?.offsetHeight}px`;
				setHeight(newHeight);
			} else {
				setHeight('100%');
			}
		}

		const resizeObserver = new ResizeObserver(calculateHeight);

		if (ref) {
			resizeObserver.observe(ref);
		}

		// Cleanup on component unmount
		return () => {
			if (ref) {
				resizeObserver.unobserve(ref);
			}
		};
	}, [mobile, ref]);

	return (
		<>
			<LoadingMessage
				style={{
					visibility: showInitialLoading ? 'visible' : 'hidden',
					margin: isDT ? '0' : '0 150px 0 0',
				}}
			>
				Loading...
				{/* We're building a base model for you.<br />Create your new furniture design from this... */}
			</LoadingMessage>

			{gltf && cameraPosition /* (console.log('canvas', currentParams) | true) && */ && (
				<Canvas
					// sessionInit={{ requiredFeatures: ['hit-test'] }}
					className="canvasWrapper"
					gl={{ antialias: true }}
					linear
					flat // about the same as 'toneMapping: THREE.NoToneMapping' in 'gl=' above
					shadows={true}
					camera={{ position: cameraPosition, fov: isDT ? 60 : 80 }}
					style={{
						filter: `blur(${globalState?.isModelLoading ? 2 : 0}px)`,
						height
					}}
				>
					<OrbitControls target={cameraTarget} zoomSpeed={.75} enableZoom={!isDT} />
					{staticScene}
					<primitive object={gltf.scene} />
					{dimensions}
					{person}
					{/* <axesHelper /> */}
				</Canvas>
			)}
		</>
	)
}

const LoadingMessage = styled(H1)`
	position: absolute;
	width: 100%;
	top: 45%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: center;
	text-color: #444;
`
