import { GlobalStateContext } from '../App'

import { useState, useEffect, useContext } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import '@google/model-viewer'

import { COMPUTE_URL } from './__urlsSystem'
import { ArMobileLinkButton, ArMobileLinkModal } from './_commonComponents'
import { backgroundColor } from './Global'
import { computeUrl, arUrl, unabbreviateParams } from './_makeUrls'
import { modelPlacement } from './_uiHelpers'
import { logAction, logArSession } from './_logging'
import { localize } from './_localization'

// import stratumIcon from './images/stratum-icon-black.png'

export default function ArModelViewer() {
	const { globalState } = useContext(GlobalStateContext)
	const { productId } = useParams()
	const [searchParams] = useSearchParams()
	const incomingParamsEncoded = Object.fromEntries(new URLSearchParams(searchParams)) // convert to an object (flat list of key:value pairs)
	// console.log('View..Block', productId, incomingParamsEncoded)

	const [qrModalIsOpen, setQrModalIsOpen] = useState(false)
	const [modelSrc, setModelSrc] = useState(false)
	const [placement, setPlacement] = useState('floor')
	const [qrUrl, setQrUrl] = useState(false)

	function QRClicked() {
		setQrModalIsOpen(true)
		logAction(productId, 'ArMobileLink')
	}

	useEffect(() => {
		// console.log('Ar..1', productId)
		logArSession(productId)
		setModelSrc(computeUrl(productId, unabbreviateParams(productId, incomingParamsEncoded)))
		setQrUrl(arUrl(productId, incomingParamsEncoded))

		// determine placement, 'floor' or 'wall'
		setPlacement(modelPlacement(productId))
		// eslint-disable-next-line
	}, [])

	const envImage = COMPUTE_URL + '/texture/music_hall_01_1k.hdr'

	return (
		<div>
			<ModelDiv>
				{/* <ReactTooltip delayShow={100} /> */}
				<ArDiv>
					<model-viewer
						src={modelSrc}
						ios-src=''
						alt=''
						shadow-intensity={'.5'}
						camera-controls={'true'}
						auto-rotate={'true'}
						environment-image={envImage}
						// skybox-image={envImage}
						ar={'true'}
						ar-modes={'webxr scene-viewer quick-look'}
						ar-scale={'auto'}
						exposure={'.6'}
						ar-placement={placement}
						style={{
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							margin: '-80px auto auto',
							backgroundColor: 'transparent',
						}}
					></model-viewer>
				</ArDiv>
				<ActionButtonDiv>
					<ArMobileLinkButton
						text={localize(
							'Get a Mobile Link|Obtenir un lien mobile|Holen Sie sich einen mobilen Link',
							globalState.language
						)}
						buttonClicked={QRClicked}
						language={globalState.language}
					/>
				</ActionButtonDiv>
				<ArMobileLinkModal
					isOpen={qrModalIsOpen}
					setIsOpen={setQrModalIsOpen}
					qrUrl={qrUrl}
					language={globalState.language}
				/>
			</ModelDiv>
		</div>
	)
}

const ModelDiv = styled.div`
	position: relative;
	background-color: ${backgroundColor};
	width: 100%;
	height: 100vh;
`

const ArDiv = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
`

const ActionButtonDiv = styled.div`
	position: absolute;
	bottom: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
`

