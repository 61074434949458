import Modal from 'react-modal'
import { GrClose } from 'react-icons/gr'

import { backgroundColor, theme } from './Global'

// standard modal dialog
export default function ConfigModal({ isOpen, onClick, children }) {
	// put the close button at the right
	const closeStyle = {
		display: 'flex',
		justifyContent: 'end',
	}

	const modalStyle = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			minWidth: '330px',
			transform: 'translate(-50%, -50%)',
			boxShadow: '4px 4px 10px rgb(0 0 0 / 25%)',
			display: 'flex',
			flexDirection: 'column',
			fontFamily: 'Sharp Sans No1 Medium',
			fontStyle: 'normal',
			fontSize: `${theme?.H2_fontSize}`
		},
		overlay: {
			position: "absolute",
			zIndex: 2,
			backgroundColor: backgroundColor
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			ariaHideApp={false}
			style={modalStyle}
			shouldCloseOnOverlayClick={true}
			onRequestClose={() => {
				onClick()
			}}
		>
			<div style={closeStyle}>
				<GrClose onClick={onClick} />
			</div>
			{children}
		</Modal>
	)
}
